.output-content
{
    border: #747474 solid 1px;
    padding: 2px 4px;
    border-radius: 3px;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    .output-content-article-code
    {
        border: #7199ff solid 1px;
        padding: 1px 2px;
        border-radius: 2px;
        margin-right: 3px;
    }
    .output-content-option
    {
        margin-left: 2px;
    }
}
.invalid-output-content
{
    color: #f00;
}
.output-content-invalid-text
{
    color: #f00;
    margin-left: 2px;
}
.lead-time-value 
{
  font-size: 1.2rem !important;
  font-weight: bold;
}

.table-responsive.column-width-control
{
    .table
    {
        th
        {
            padding: 0.75rem 0.5rem;
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            background-color: #f3f6f9;
        }
        
        td
        {
            padding: 0.25rem 0.5rem;
        }
        
        td.combination-column
        {
            padding: 0.25rem 0.5rem;
        }
    }
}

.table tr
{
    line-height: 1.2;
}

.text-center
{
    text-align: center !important;
}

.lead-time-header {
  white-space: nowrap;
  min-width: 120px;
}