@import "../../_metronic/_assets/sass/components/_variables.bootstrap.scss";

@media (min-width: 576px) {
  .modal-dialog.routing-rule {
    max-width: 60% !important;
    margin: 1.75rem auto;
  }
}

.position-sticky-below-topbar {
  position: sticky !important;
  top: 65px;
  z-index: $zindex-dropdown - 10;
}

.align-self-anchor-center {
  align-self: anchor-center;
}