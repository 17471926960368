.schedule-data-container {
  padding: 1rem 1rem 1rem 0;
}

// Override bootstrap datepicker and modal behaviour :(
.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 2px !important;
}

@media (min-width: 576px) {
  .modal-dialog.leadtime {
    max-width: 85% !important;
    margin: 1.75rem auto;
  }
}

.custom-select {
  &.select-schedule-name {
    //  width: 100% !important; // Update here to change select length
    height: auto !important;
  }
}