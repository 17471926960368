.output-content
{
    border: #747474 solid 1px;
    padding: 5px;
    border-radius: 3px;
    .output-content-article-code
    {
        border: #7199ff solid 1px;
        padding: 3px;
        border-radius: 3px;
    }
}
