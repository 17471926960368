// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}

.break-header {
  white-space: pre-wrap;
}

// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;
}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.column-width-control th,
.column-width-control td {
  border: none;
}

.custom-name-header {
  min-width: 100px;
  width: 20%;
}

.text-center {
  text-align: center;
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.list-container {
  font-size: 18px;
}

.btn-add-new-routing-rule {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn-schedule {
  width: 24px;
  height: 24px;
}

.btn-delete-routing-rule {
  position: absolute;
  top: 0;
  right: 0;
}

.item-container {
  position: relative;
}

// Set up custom colours for new design
$theme: rgb(0, 115, 119);

.btn-primary-theme {
  background-color: $theme;
  color: white;
  border-color: $theme;
  font-weight: 500;
}

.btn-primary-theme:hover {
  background-color: lighten($theme, 10%);
  color: white;
  border-color: lighten($theme, 10%);
}

.btn-primary-theme:focus,
.btn-primary-theme.focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 194, 194, 0.5);
}

.btn-primary-theme.disabled,
.btn-primary-theme:disabled {
  color: #212529;
  background-color: #7cc;
  border-color: #5bc2c2;
}

.btn-outline-theme {
  color: $theme;
  border-color: $theme;
  font-weight: 500;
}

.btn-outline-theme:hover {
  color: #fff;
  background-color: $theme;
  border-color: $theme;
}

.btn-outline-theme.focus,
.btn-outline-theme:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-theme.disabled,
.btn-outline-theme:disabled {
  color: #007bff;
  background-color: transparent;
}

.hidden-header {
  display: none;
}

.table td.reset-expansion-style {
  padding: 0;
  border: none; // remove thick border on border-collapse
}

.row-expansion-style .table {
  margin-bottom: 0;
}

.width-set {
  width: 20%;
}

.width-set-100 {
  width: 100%;
}

.width-set-95 {
  width: 95%;
}

.width-set-90 {
  width: 90%;
}

.width-set-85 {
  width: 85%;
}

.width-set-80 {
  width: 80%;
}

.width-set-75 {
  width: 75%;
}

.width-set-70 {
  width: 70%;
}

.width-set-55 {
  width: 55%;
}

.width-set-50 {
  width: 50%;
}

.width-set-45 {
  width: 45%;
}

.width-set-35 {
  width: 35%;
}

.width-set-30 {
  width: 30%;
}

.width-set-25 {
  width: 25%;
}

.width-set-20 {
  width: 20%;
}

.width-set-15 {
  width: 15%;
}

.width-set-10 {
  width: 10%;
}

.width-set-5 {
  width: 5%;
}

.semi-bold {
  font-weight: 500;
}

.lighter {
  font-weight: 400;
  opacity: 0.6;

  .stronger {
    font-weight: 500;
  }
}

.routing-rule-text-smallcaps {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 450;
  line-height: 13.9px;
  letter-spacing: 0.265em;
}

.custom-expand-cell {
  width: 4%;
  min-width: 38px;
}

.expand-cell {
  width: 4%;
  min-width: 38px;
  cursor: pointer;
}

.expand-cell-header {
  cursor: pointer;
}

.table-action-icons-container {
  display: flex;
  justify-content: space-between;
}

.table-action-icons-container-leftaligned {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 8px;
}

.table-action-icons-container-button {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  background-color: transparent;
  border: 0;
}

.table-action-icons-container-button-small {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

.table-action-icons-children {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 52px;
  text-align: center;
  font-size: 8px;
  color: #818383;
  background-color: transparent;
  border: 0;

  .icon {
    margin-bottom: 8px;
  }
}

.table-action-icons-children-small {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  text-align: center;
  font-size: 8px;
  color: #818383;
  background-color: transparent;
  border: 0;

  .icon {
    margin-bottom: 8px;
  }
}

.active {
  background-color: rgba(0, 115, 119, 0.2);
}

.active-header {
  background-color: rgba(0, 115, 119, 0.1);
}

.routing-rules-changed {
  background-color: rgba(245, 248, 255, 1);
}

.routing-rules-test-success {
  background-color: rgba(192, 246, 244, 1);
}

.routing-rules-test-failed {
  background-color: rgba(253, 211, 215, 1);
}

.active-leadtime {
  color: #080;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.font-small {
  font-size: 11px;
}

.search-input-group {
  input {
    border-right: none;
  }

  input:focus {
    border-color: $input-border-color;
  }

  button {
    border-left: none;
    background-color: #ffffff;
  }
}

.visibility-hidden {
  visibility: hidden;
}